<template>
  <div class="app-container">
    <h2>{{ $t('orderFromRobex.ordering') }}</h2>
    <el-link v-if="robexWebshopURL" :href="robexWebshopURL" target="_blank">
      {{ $t('orderFromRobex.loginToRobexWebShop') }}
    </el-link>
    <p v-if="!robexWebshopURL">
      {{ $t('orderFromRobex.requestAccessToRobexWebShop') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['robexWebshopURL'])
  },
  created() {}
};
</script>
